var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"box-wrapper-main"},[_c('div',{staticClass:"box-left-brd",style:({
      '--colour': _vm.boxOptions.BgColor,
      '--circle-width': `${_vm.boxOptions.circleWidth}px`,
      '--circle-width2': `${_vm.boxOptions.circleWidth + 1}px`,
      '--width': `${_vm.boxOptions.width}px`,
    })}),_c('div',{staticClass:"box-content-wrapper",style:(`background-color: ${_vm.boxOptions.BgColor}`)},[_vm._t("box-content")],2),_c('div',{staticClass:"box-right-brd",style:({
      '--colour': _vm.boxOptions.BgColor,
      '--circle-width': `${_vm.boxOptions.circleWidth}px`,
      '--circle-width2': `${_vm.boxOptions.circleWidth + 1}px`,
      '--width': `${_vm.boxOptions.width}px`,
    })})])
}
var staticRenderFns = []

export { render, staticRenderFns }